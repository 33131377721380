
























































import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import ProgressLine from '@/components/ProgressLine.vue'

@Component({
  components: {
    ContentArea,
    Title,
    PageLayout,
    PrimaryButton,
    ProgressLine
  }
})
export default class Quidel extends Vue {
  private topShadow = false
  private bottomShadow = true
  private oldScroll = 0
  locale = ''
  private scrollEvent (ev: { target: HTMLDivElement }): void {
    if (this.oldScroll > ev.target.scrollTop) {
      this.topShadow = true
      this.bottomShadow = false
    } else {
      this.topShadow = false
      this.bottomShadow = true
    }
    if (ev.target.scrollTop === 0) {
      this.topShadow = false
      this.bottomShadow = true
    }
    this.oldScroll = ev.target.scrollTop
  }

  private submit (): void {
    window.open('https://quickvueathome.com/')
  }

  private mounted (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
    this.locale = localStorage.getItem('locale') as string
  }

  private goBack (): void {
    this.$router.go(-1)
  }
}
